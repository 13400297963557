import { GetServerSideProps, GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import styled, { ThemeProvider } from 'styled-components';
import Footer from '../components/BrandSitesV2/common/Footer';
import { BrandSitesV2GlobalStyle, BrandSitesV2Theme } from '../components/BrandSitesV2/common/GlobalStyle';
import Header from '../components/BrandSitesV2/common/Header';
import StickyAppDownloadButton from '../components/BrandSitesV2/common/StickyAppDownloadButton';
import BusinessSection from '../components/BrandSitesV2/Home/BusinessSection';
import HeroSection from '../components/BrandSitesV2/Home/HeroSection';
import HomeCleanSection from '../components/BrandSitesV2/Home/HomeCleanSection';
import NewsSection from '../components/BrandSitesV2/Home/NewsSection';
import StorySection2 from '../components/BrandSitesV2/Home/StorySection2';
import TogetherSection from '../components/BrandSitesV2/Home/TogetherSection';
import { apiClient } from '../lib/axios/apiClient';
import { News } from './news';
import { GoogleAnalyticsBrandsiteScript } from '../components/GoogleAnanlytics';

const CY_STORE = 'cy-store';

interface PageProps {
  highlightNews?: News[];
}

export const getServerSideProps: GetServerSideProps<PageProps> = async (context) => {
  const { headers } = context.req;

  if ([headers.host, headers.forwarded, headers['x-forwarded-host']].some((host) => host?.includes(CY_STORE))) {
    return { redirect: { destination: '/commerce/home', permanent: true } };
  }

  const highlightNews = await apiClient
    .get(`${process.env.NEXT_PUBLIC_BASE_URL}/brand_site/highlight_news?c=lab`)
    .then((res) => res.data?.list);

  return { props: { highlightNews } };
};

const Home: NextPage<PageProps> = ({ highlightNews }) => {
  return (
    <>
      <Head>
        <title>청소연구소</title>
        <meta property="og:title" content="청소연구소" />
        <meta name="description" content="청소를 바꾸다. 생활을 바꾸다." />
        <meta property="og:description" content="청소를 바꾸다. 생활을 바꾸다." />
        <meta name="keywords" content="청소연구소,집청소,가사도우미,사무실 청소,생활용품,청소용품,청연스토어" />
        <meta name="og:keywords" content="청소연구소,집청소,가사도우미,사무실 청소,생활용품,청소용품,청연스토어" />
        <meta property="og:url" content="https://www.cleaninglab.co.kr" />
        <link rel="canonical" href="https://www.cleaninglab.co.kr" />
        <meta
          property="og:image"
          content="https://cleanhome-web.cleaninglab.co.kr/brandsite/home/thumb.png"
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="360" />
        <meta property="og:image:type" content="image/png" />
        <GoogleAnalyticsBrandsiteScript />
      </Head>
      <BrandSitesV2GlobalStyle />
      <ThemeProvider theme={BrandSitesV2Theme}>
        <Header />
        <Main>
          <HeroSection />
          <HomeCleanSection />
          <StickyContainer>
            <TogetherSection />
            <BusinessSection />
            <StorySection2 />
            <NewsSection highlightNews={highlightNews || []} />
            <StickyAppDownloadButton />
          </StickyContainer>
        </Main>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Home;

const Main = styled.main`
  position: relative;
`;

const StickyContainer = styled.div``;
